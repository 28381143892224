<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <h4>تفاصيل المادة {{ subjectDetailDto.name }}</h4>
            </b-card-header>
            <b-card-body>
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col lg="4">
                            <ek-input-text name="name" label=" اسم المادة " placeholder="ادخل اسم المادة"
                                v-model="subjectDetailDto.name">
                            </ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="6" md="12">
                                    <ek-input-text readonly
                                        :placeholder="years.find(item => item.id == subjectDetailDto.yearId).name"
                                        name=" اختر السنة" label="السنة">
                                    </ek-input-text>
                                </b-col>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-text readonly label="الفصل"
                                        :placeholder="semesters.find(item => item.id == subjectDetailDto.semesterId).name">
                                    </ek-input-text>
                                </b-col>
                            </b-row>
                            <ek-input-text name="name" label=" سعر المادة " v-model="subjectDetailDto.price"
                                placeholder="سعر المادة">
                            </ek-input-text>
                            <ek-input-text readonly type="number" name="name" label=" عدد المشتركين "
                                placeholder="عدد المشتركين" v-model="subjectDetailDto.subscribersCount">
                            </ek-input-text>
                        </b-col>
                        <b-col lg="4">
                            <ek-input-text readonly name="classes"
                                :placeholder="universityNames.find(item => item.id == subjectDetailDto.universityId).name"
                                label="الجامعة" clearable @change="resetUniversity"></ek-input-text>
                            <ek-input-text v-if="subjectDetailDto.universityId" name="classes" readonly
                                :placeholder="universityNames.find((u) => u.id == subjectDetailDto.universityId).faculties.find(item => item.id == subjectDetailDto.facultyId).name"
                                label="الكلية"></ek-input-text>

                            <ek-input-text readonly v-if="subjectDetailDto.facultyId"
                                :placeholder="universityNames.find((u) => u.id == subjectDetailDto.universityId).faculties.find((f) => f.id == subjectDetailDto.facultyId).departments.find(item => item.id == subjectDetailDto.departmentId).name"
                                label="الفرع">
                            </ek-input-text>
                        </b-col>
                        <b-col lg="4" class="position-relative">
                            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret
                                class="position-absolute">
                                <template #button-content>
                                    <unicon name="ellipsis-v" fill="#6101DF"></unicon>
                                </template>
                                <b-dropdown-item href="#" ref="myUploader" @click="fileWindowApi(onUpload)">
                                    <unicon name="edit-alt" fill="#6101DF" style="width: 25px"></unicon>
                                    <span>تعديل الصورة</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <img class="h-100 w-100 rounded" style="object-fit: cover" :src="$store.getters['app/domainHost'] +
                                '/' +
                                subjectDetailDto.url
                                " alt="" />
                        </b-col>
                        <b-col lg="12">
                            <ek-input-textarea name="name" label="شرح المادة" placeholder="شرح المادة"
                                v-model="subjectDetailDto.description">
                            </ek-input-textarea>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-card-body>
        </b-card>
        <div class="d-flex justify-content-end align-items-center">
            <div class="d-flex justify-content-center align-items-center">
                <ek-input-text v-model="searchVal" name="ابحث عن كورس محدد" placeholder="ابحث عن كورس محدد"
                    @input="filterSearch">
                </ek-input-text>
                <AddCourse class="ml-2" @fillSelectCourse="
                    $store.commit('Fill_Select_Course', subjectDetailDto)
                    "></AddCourse>
            </div>
        </div>
        <ek-table :columns="columns" :items="filterCourses" @details="detailsCourse">
            <template #table-header>
                <div class="d-flex p-1">
                    <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
                    <h4>كورسات المادة</h4>
                </div>
            </template>

            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
import AddCourse from "@admin/courses/components/AddCourse.vue";

export default {
    props: {
        id: String,
    },
    components: {
        AddCourse,
    },
    data: () => ({
        searchVal: "",
        isCourse: true,

        columns: [
            {
                label: "اسم الكورس ",
                field: "name",
            },
            {
                label: "ترتيب الكورس ",
                field: "order",
            },
            {
                label: "عدد الفيديوهات",
                field: "videoCount",
            },
            {
                label: "عدد المستندات",
                field: "documentCount",
            },
            {
                label: "تاريخ الاضافة",
                field: "dateCreated",
            },
            {
                label: "تفاصيل",
                field: "details",
            },
        ],
    }),
    computed: {
        ...mapState({
            subjectDetailDto: (state) => state.subjects.subjectDetailDto,
            universityNames: (state) => state.university.universityName,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
        }),
        ...mapGetters(["filterCourses"]),
    },
    methods: {
        ...mapActions([
            "getSubjectDetails",
            "updateSubject",
            "deleteSubject",
            "getNamesUniversity",
            "getSettingSemester",
            "getSettingYear",
            "addFile",
        ]),
        fileWindowApi,
        onUpload(e) {
            if (e.file) {
                this.addFile({ ...e, folderName: "Profile" }).then((res) => {
                    this.subjectDetailDto.url = res.data;
                });
            }
        },
        resetUniversity() {
            this.subjectDetailDto.facultyId = "";
        },
        resetFaculty() {
            this.subjectDetailDto.departmentId = "";
        },
        detailsCourse({ row }) {
            this.$router.push(`/admin/courses/${row.id}`);
        },
        filterSearch() {
            this.$store.commit("Filter_Course", this.searchVal);
        },
    },
    // beforeDestroy() {
    //   this.$store.commit("Set_Subjects_Dto");
    // },
    created() {
        this.getSubjectDetails(this.id).then(() => {
            this.$store.commit("Fill_Select_Course", this.subjectDetailDto);
        });
        this.getNamesUniversity();
        this.getSettingYear();
        this.getSettingSemester();
    },
    beforeDestroy() {
        this.$store.commit("Reset_Course");
    },
    mounted() { },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
    right: 0 !important;
}
</style>
